body {
    margin: 0;
}

#root {
    height: 100%;

    .App {
        color: red;
    }
}
