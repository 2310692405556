.publish {
    position: relative;

    .ql-container {
        height: 400px;
    }    
}

.ant-upload-list {
    .ant-upload-list-picture-card-container,
    .ant-upload-select {
        width: 146px;
        height: 146px;
    }
}
